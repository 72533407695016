import './polyfills/polyfills.js';
import './bootstrap/util.js';
import './bootstrap/collapse';
import './bootstrap/carousel';
import './bootstrap/modal';
import './bootstrap/tab';

import * as actions from './actions/actions';

import {
	resized
} from '@area17/a17-helpers';
import * as Behaviors from './behaviors';
import {
	manageBehaviors
} from './functions/manageBehaviors';

// HTML4 browser?
if (!NCL.browserSpec || NCL.browserSpec === 'html4') {
	// lets kill further JS execution of NCL js here
	throw new Error('HTML4');
}

document.addEventListener('DOMContentLoaded', function () {

	// go go go
	manageBehaviors(Behaviors);

	// Form Stuff
	actions.actionsFormAge();
	actions.actionsFormToggle();
	actions.actionsFormDynamic();
	actions.actionsFormValidate();
	actions.actionsNavigation();
	actions.actionsFilterToggle();
	actions.actionsHeroList();
	actions.actionsHeaderButton();
	actions.actionsRolloverComponent();

	// on resize, check
	resized();

});

