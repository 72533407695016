import createBehavior from '../functions/createBehavior';

const gallery = createBehavior(
  'gallery',
  {
    initMap(e) {
      let bounds = new google.maps.LatLngBounds();
      const data = JSON.parse(this.mapData);
      const map = new google.maps.Map(this.container, {
        zoom: 10,
      });
      if (data) {
        this.setMarkers(map, data, bounds);
        map.fitBounds(bounds);
      }
      google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
        if (this.getZoom() > 15) {
          this.setZoom(15);
        }
      });
    },
    setMarkers(map, data, bounds) {
      let prevWindow = false;

      let icon = {
        url: 'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent('<svg width="58" height="81" viewBox="0 0 58 81" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M29 0C13.0093 0 0 13.0092 0 28.9998C0 48.8446 25.9522 77.9778 27.0571 79.2082C28.0949 80.3643 29.9069 80.3621 30.9429 79.2082C32.0478 77.9778 58 48.8446 58 28.9998C57.9997 13.0092 44.9905 0 29 0ZM29 43.5905C20.9547 43.5905 14.4095 37.045 14.4095 28.9998C14.4095 20.9545 20.9548 14.4094 29 14.4094C37.0452 14.4094 43.5903 20.9547 43.5903 29C43.5903 37.0452 37.0452 43.5905 29 43.5905Z" fill="#0F55A2"/> </svg> '),
        scaledSize: new google.maps.Size(58, 81),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(29, 81),
        labelOrigin: new google.maps.Point(0, 0),
      };

      let gmarkers = [];
      for (let i = 0; i < data.length; i++) {
        let mapPoint = data[i];

        if(mapPoint.latitude !== '' && mapPoint.latitude !== ''){
          let lat = mapPoint.latitude;
          let lng = mapPoint.longitude;
          let latLng = new google.maps.LatLng(lat, lng);
          let marker = new google.maps.Marker({map: map, position: latLng, icon: icon});
          gmarkers.push(marker);

          bounds.extend(marker.position);

          (function (marker, mapPoint) {
            let windowContent = `<div class="m-map__popup">
              ${mapPoint.title !== '' ? `<h3>${mapPoint.title}</h3>` : ''}
              <span>
                ${mapPoint.address1 !== '' ? `${mapPoint.address1}<br/>` : ''}
                ${mapPoint.address2 !== '' ? `${mapPoint.address2}<br/>` : ''}
                ${mapPoint.city !== '' ? `${mapPoint.city}<br/>` : ''}
                ${mapPoint.county !== '' ? `${mapPoint.county}<br/>` : ''}
                ${mapPoint.postcode !== '' ? `${mapPoint.postcode}<br/>` : ''}
              </span>
              ${mapPoint.direction !== '' ? `<a href="${mapPoint.directions}" class="a-text-link" target="_blank">Get Directions <svg class="icon--arrow" aria-hidden="true"> <use xlink:href="#icon--arrow"></use> </svg></a>` : ''}
            </div>`;

            let infowindow = new google.maps.InfoWindow({
              pixelOffset: new google.maps.Size(180, 180),
              content: '<div class="m-map__popup-wrap">' + windowContent + '</div>'
            });

            // Open info window on click
            google.maps.event.addListener(marker, 'click', function () {
              if (prevWindow) {
                prevWindow.close();
              }
              prevWindow = infowindow;
              map.panTo(this.getPosition());
              infowindow.open(map, marker);
            });
          }(marker, mapPoint));
        }

        google.maps.event.trigger(gmarkers[0], 'click');
      }
    },
    isScriptAlreadyIncluded(src){
      let scripts = document.getElementsByTagName('script');
      for(let i = 0; i < scripts.length; i++) {
        if(scripts[i].getAttribute('src') === src) {
          return true;
        }
      }
      return false;
    },
    loadScript(url, callback)
    {
      if(!this.isScriptAlreadyIncluded(url)){
        let head = document.head;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onreadystatechange = callback;
        script.onload = callback;
        head.appendChild(script);
      } else {
        callback();
      }
    },
    loadMaps(key) {
      this.loadScript(`https://maps.googleapis.com/maps/api/js?key=${key}`, this.initMap);
    },
  },
  {
    init() {
      this.container = this.node.querySelector('[data-map-container]');
      this.mapData = this.node.getAttribute('data-mapData');
      this.key = 'AIzaSyAsdzDNFvTqX_i1RlKT-n5_Tzo2c7CKh4Q'; // TODO: Change to client's API key

      window.addEventListener('load', this.loadMaps(this.key));
    },
    destroy() {
      window.removeEventListener('load', this.loadMaps(this.key));
    },
  }
);

export default gallery;
