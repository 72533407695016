import $ from 'jquery'

function actionsForm() {

	let $applicationField = `
		<div class="row app-form-field">
			<div class="col-1">
				<a href="#" class="app-qualifications-remove">Remove Me</a>
			</div>
			<div class="col-3">
				<input type="text" class="form-control" name="appQualificationName" id="appQualificationName" value="" required />
			</div>
			<div class="col-4">
				<div class="m-form__select">
					<select class="form-control" name="appQualificationsGrade" id="appQualificationsGrade" required>
						<option selected disabled>-</option>
						<option value="#">Grade A</option>
						<option value="#">Grade B</option>
						<option value="#">Grade C</option>
						<option value="#">Grade D</option>
						<option value="#">Grade E</option>
						<option value="#">Grade F</option>
						<option value="#">Grade G</option>
						<option value="#">Grade H</option>
					</select>
				</div>
			</div>
			<div class="col-4">
				<div class="col-12 mb-3 col-sm-6 mb-sm-0 col-md-6 mb-md-4 col-xl-6 mb-xl-0">
					<div class="m-form__radio form-radio-inline">
						<input type="radio" id="appQualificationsType1" name="appQualificationsType" value="true">
						<label for="appQualificationsType1">Predicted</label>
					</div>
				</div>
				<div class="col-12 mb-3 col-sm-6 mb-sm-0 col-md-6 mb-md-4 col-xl-6 mb-xl-0">
					<div class="m-form__radio form-radio-inline">
						<input type="radio" id="appQualificationsType2" name="appQualificationsType" value="false" checked>
						<label for="appQualificationsType2">Actual</label>
					</div>
				</div>
			</div>
		</div>`;


	// Click To Add New Fields
	$('.app-qualifications-add').click(function (e) {
		e.preventDefault()
		$($applicationField).insertBefore(this)
	});

	// Click To Remove Fields
	$(document).on('click', '.app-qualifications-remove', function (e) {
		e.preventDefault()
		$(this).closest('.app-form-field').remove()
	})

}

export default actionsForm
