import $ from 'jquery'

function actionsRolloverComponent() {
	$('.js-toggle-bg li').hover(function () {

    var value = $(this).attr('data-src');
    var newText = $(this).attr('data-newtext');
	console.log(value);

    $(this).closest('.js-toggle-bg').find('.m-hero__image').removeClass (function (index, className) {
      return (className.match (/(^|\s)m-hero__image--\S+/g) || []).join(' ');
    });


    $(this).closest('.js-toggle-bg').find('.m-hero__image').addClass(value);
	
	$(this).closest('.js-toggle-bg').find('.m-hero__image h2').text(newText);
  });
  
  $('.js-toggle-bg-stats li').hover(function () {
	  
	  var deParent = $(this).closest('.js-toggle-bg-stats');
	  var deTarget = $(this).attr('data-targetId');
	  
	  $(deParent).find('[data-target-id]').attr('style', 'display:none!important;');
	  
	  if(deTarget !== undefined){
		 $(deParent).find('[data-target-id="' + deTarget + '"]').attr('style', '');
	  } else {
		 $(deParent).find('[data-target-id="default"]').attr('style', ''); 
	  }
	
  });
}

export default actionsRolloverComponent
