import $ from 'jquery'

function actionsHeaderButton() { 
	$('.a-btn--apply').hover(function () {
    $('.a-btn--apply__modal').toggleClass('active');
  });
}


export default actionsHeaderButton
