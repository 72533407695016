import $ from 'jquery'

function actionsFilterToggle() {
	$('.m-filter__select').click(function () {
    $(this).toggleClass('active');
  });

  $('.icon--search-sidebar').click(function () {
		$('.search--sidebar-hidden').toggle();
  });

  $('.icon--caret--down').click(function () {
    $(".m-hero__links").toggleClass("active");
  });
}


export default actionsFilterToggle
